/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import img from '../images/how-to-use-openvpn-in-china-lmao.png';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {Layout as MDXLayout} from "../components/layout";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    ol: "ol",
    li: "li",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "the byzantine ridiculousness of remote work from mainland china (w.r.t openvpn)"), "\n", React.createElement(_components.p, null, "i've spent quite a lot of time during the last few years living in mainland\nchina. the 跳墙 meta has evolved quite a lot over the last decade or so. from\ngetting away with simply tunneling http traffic thru ssh back in the day, to using\nthe shadowsocks protocol and now to those like vmess and trojan (i expect the\ncat and mouse game of these newer protocols to continue, where a dev gets v&\nand new ones come in to fill the spot)."), "\n", React.createElement(_components.p, null, "in 2023 openvpn is completely unusable over home internet lines in china\ndue to DPI capabilities of the GFW. connections to a fresh openvpn server\nwill last from maybe a few minutes to a day before the banhammer comes down.\nthis posed a major problem for me, since i was working for a client where\nsome resources were restricted behind an ACL and could only be reached using\na connection to their openvpn server."), "\n", React.createElement(_components.p, null, "since i was already using v2ray (with vmess protocol) to jump over the firewall,\ni decided to just try the OOTB functionality of openvpn to route it through\na socks proxy using shadowsocks over ", React.createElement(_components.a, {
    href: "https://github.com/shadowsocks/shadowsocks/wiki/Connect-to-OpenVPN-over-Shadowsocks"
  }, "v2ray"), ".\nthis did eventually work with some caveats:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "i had to setup my v2ray server to route all connections, it didn't seem to\nwork with rule based routing"), "\n", React.createElement(_components.li, null, "connection was unstable"), "\n"), "\n", React.createElement(_components.p, null, "i looked around for a bit to see if anyone else had needed to do a similar\nthing and luckily this ", React.createElement(_components.a, {
    href: "https://github.com/zpoint"
  }, "legend"), " had done so (albeit with the\nusecase of accessing netflix and other streaming sites from the mainland); furthermore\nhe had a ", React.createElement(_components.a, {
    href: "https://github.com/zpoint/openvpn_socks5_docker"
  }, "whole repo"), " dedicated to\nit."), "\n", React.createElement(_components.p, null, "the gist of the repo is that it's a docker container that runs an openvpn\nclient and a dante server. you run this container on the host with your v2ray\nserver. you would config v2ray to pass traffic you want going through openvpn,\nto go to a local socks port (dante in the container). dante then passes this\nthru to openvpn (in the container)."), "\n", React.createElement(_components.p, null, "here crude network diagram of whats going on:"), "\n", React.createElement("img", {
    src: img,
    alt: "network diagrom showing how to route openvpn via a v2ray and socks proxy"
  }), "\n", React.createElement(_components.p, null, "and here's the server side v2ray conf i'm using:"), "\n", React.createElement(SyntaxHighlighter, {
    language: "json"
  }, `{
  "log": {
    "loglevel": "info",
      "access": "/var/log/v2ray/access.log",
      "error": "/var/log/v2ray/error.log"
  },
    "inbounds": [
    {
      "port": XXXX,
      "listen":"127.0.0.1",
      "protocol": "vmess",
      "settings": {
        "clients": [
        {
          "id": "YYYY",
          "alterId": 64
        }
        ]
      },
      "streamSettings": {
        "network": "ws",
        "wsSettings": {
          "path": "/ray"
        }
      }
    }
    ],
    "outbounds": [
    {
      "protocol": "freedom",
      "settings": {
      }
    },
    {
      "protocol": "socks",
      "settings": {
        "servers": [{
          "port": ZZZZ,
          "address": "127.0.0.1"
        }]
      },
      "streamSettings": {
        "tcpSettings": {
          "header": {
            "type": "none"
          }
        },
        "network": "tcp",
        "security": "none"
      },
      "tag": "work"
    }
    ],
    "routing": {
      "domainStrategy": "IPOnDemand",
      "rules": [
      {
        "type": "field",
        "domain": [
          "domain:AAAA"
        ],
        "outboundTag": "work"
      }
      ]
    }
}
`), "\n", React.createElement(_components.p, null, "where:"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "XXXX"), ": is the port my v2ray server listens on, and which my v2ray client connects to"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "YYYY"), ": is a guid that is the same on v2ray client and server"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "ZZZZ"), ": is the port on which the dante socks server (from docker conatiner) is listening"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "AAAA"), ": is the wildcard rule for domains which are part of my clients intranet"), "\n", React.createElement(_components.p, null, "and this is the corresponding (minimal and redacted) v2ray config on client side:"), "\n", React.createElement(SyntaxHighlighter, {
    language: "json"
  }, `{
  "outbounds": [
    {
      "protocol": "vmess",
      "streamSettings": {
        "wsSettings": {
          "path": "/ray",
        },
        "tlsSettings": {
          "allowInsecure": false
        },
        "security": "tls",
        "network": "ws"
      },
      "tag": "proxy",
      "settings": {
        "vnext": [
          {
            "address": "XXXX",
            "users": [
              {
                "id": "YYYY",
                "alterId": 0,
                "level": 0,
                "security": "auto"
              }
            ],
            "port": 443
          }
        ]
      }
    }
  ]
}
`), "\n", React.createElement(_components.p, null, "where:"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "XXXX"), " is domain name of my v2ray server"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "YYYY"), " is same guid as on server"), "\n", React.createElement(_components.p, null, "obv you will have to setup a domain name which would be the ", React.createElement(_components.code, null, "XXXX"), " in my example config - so i'd recommend doing this thru smth like cloudflare, so that if the GFW randomly blocks ur connections, you can just reset and cloudflare will proxy your server via a different IP.\nyou can easily do this reverse proxy thing thru nginx, or even easier nowadays caddy (cos it has automatic TLS certs). this will be left as an exercise for the reader. this ", React.createElement(_components.a, {
    href: "https://www.linuxbabe.com/ubuntu/set-up-v2ray-proxy-server"
  }, "page"), " has everything you'd need for setting up v2ray w ur own domain name."));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
